.container {
    background-color: #f6f7fa;
    min-width: 100vw;
    min-height: 100vh;

    .header-logo {
        text-align: center;
        padding-top: 50px;
        margin-bottom: 40px;

        .logo {
            width: 160px;
            height: 80px;
        }
    }

    .body-container {
        padding: 0px 80px;

        .content {
            text-align: center;
            border-radius: 4px;
            box-shadow: 0 4px 12px 0 #e8e8e8;
            background-color: #fff;
            padding: 40px;

            .img-content {
                width: 256px;
            }

            .text-header {
                margin: 20px 0px;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: #25282b;
            }

            .text-desc {
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.56;
                letter-spacing: normal;
                text-align: center;
                color: #666;
            }

            .button-back {
                margin-top: 20px;
                border: none;
                background-color: #25282b;
                width: 256px;
                height: 56px;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fbb12f;
            }
        }
    }

    .footer-container {
        margin-top: 20px;
        text-align: center;
        padding: 30px 30vw;

        .text {
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: center;
            color: #555860;

            a {
                color: #fbb12f !important;
            }
        }
    }

    .version-container {
        bottom: 0;
        padding: 15px 10vw;
        width: 80vw;

        .text {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: center;
            color: #555860;

            a {
                color: #fbb12f !important;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .container {
        .header-logo {
            padding-top: 40px !important;
            margin-bottom: 30px !important;

            .logo {
                width: 35vw !important;
                height: auto !important;
            }
        }
    }

    .body-container {
        padding: 0px 10vw !important;

        .content {
            .img-content {
                width: 50vw !important;
            }

            .text-header {
                // margin: 20px 0px;
                font-size: 20px !important;
            }

            .text-desc {
                font-size: 14px !important;
            }

            .button-back {
                // margin-top: 20px;
                width: 40vw !important;
                height: auto !important;
                font-size: 14px !important;
                min-height: 40px;
            }
        }
    }

    .footer-container {

        .text {
            // margin-bottom: 20px;
            font-size: 10px !important;
        }
    }

    .version-container {

        .text {
            font-size: 10px !important;
        }
    }
}


//loader
.sk-chase {
    margin-top: 10px;
    margin-bottom: 42px;
    margin-left: calc(50vw - 100px);
    width: 50px;
    height: 50px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #000;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
    animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
    animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
}

@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot {

    80%,
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    }

    100%,
    0% {
        transform: scale(1.0);
    }
}